import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CSubheader,
	CCardBody,
	CFooter,
	CForm,
	CRow,
	CCol,
	CFormInput,
	CFormSelect,
	CLink,
	CTooltip,
	CInputGroup,
	CInputGroupText,
	CButton,
	CListGroup,
	CListGroupItem,
	CBadge,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
	MyDialoglookup,
	MyDialogform,
	MyProfileDetil,
	MyPage500,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     	= require('../../../package.json');
const Beliasetmain 	= (props) => {
	const uComponentname	= Beliasetmain.name;
	const { 
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate 		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//---MAIN_VARS--/
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatamainObj,setDatamainObj]		= React.useState({});
	const uDatatabelArr 					= uDatamainObj.items_arr||[];
	const [uDataformObj,setDataformObj]		= React.useState({});
	const [uTabeleditObj,setTabeleditObj]	= React.useState({});
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [uIsFormupdate,setFormupdate]		= React.useState(false);
	const [uIsFormprocess,setIsFormprocess]	= React.useState(false);
	const [uIsSuppliernew,setSuppliernew]	= React.useState(false);
	const [uKasGLArr,setKasGLArr]			= React.useState([]);
	const [uDatasupplierArr,setDatasupplierArr]= React.useState([]);//-*/
	const [uDataasetjenisArr,setDataasetjenisArr]= React.useState([
		{value:"0",caption:(uBahasaObj.caption_dataempty||"caption_dataempty")},
	]);//-*/
	//---END MAIN_VARS--/

	//--FORM_DIALOG--/
	const [uISDlgformshow,setDlgformshow] 		= React.useState(false);
	const [uDlgforminputObj,setDlgforminputObj] = React.useState({});
	//--END FORM_DIALOG--/

	//--LOOKUP_DIALOG--/
	const [uDlglookupjenis,setDlglookupjenis] = React.useState("");
	const [uInpDlglookup,setInpDlglookup] 		= React.useState("");
	const [uDlglookupheader,setDlglookupheader] = React.useState("");
	const [uIsDlglookupshow,setDlglookupshow] 	= React.useState(false);
	const [uDataDlglookupArr,setDataDlglookupArr] = React.useState([]);
	//--END LOOKUP_DIALOG--/
	
	//---DOM_COMPONENT--/
	/*const uElCardnota		= document.getElementById("idcardnota");
	const uElCardsupplier	= document.getElementById("idcardsupplier");
	const uElCardfooterkiri	= document.getElementById("idcardfooterkiri");
	const uElCardfooterkanan= document.getElementById("idcardfooterkanan");
	//---END DOM_COMPONENT--*/

	//---HANDEL--/
	const hdlToggleDlglookup = () => { setDlglookupshow(false); }
	const hdlKlikDlglookuppilih=(_IDITEM)=>{
		const vIdx = UFunc.getObjectIndeks(uDataDlglookupArr,_IDITEM,"id");
		if(vIdx < 0) return;

		let vElsfocusnext;
		if(uDlglookupjenis==="KAS") {
			vElsfocusnext = document.getElementById("inpnilaibayar");
			uDatamainObj.id_gl_kas 		= (uDataDlglookupArr[vIdx].id)
			uDatamainObj.nama_gl_kas 	= (uDataDlglookupArr[vIdx].caption)
		} else if(uDlglookupjenis==="SUPPLIER") {
			vElsfocusnext = document.getElementById("inpformitemnama");
			uDatamainObj.id_supplier	= (uDataDlglookupArr[vIdx].id)
			uDatamainObj.nama_supplier	= (uDataDlglookupArr[vIdx].caption)
		} else if(uDlglookupjenis==="FORMJENIS") {
			vElsfocusnext = document.getElementById("inpformitemqty");
			uDlgforminputObj.jenisaset_id	= (uDataDlglookupArr[vIdx].id)
			uDlgforminputObj.jenisaset_caption= (uDataDlglookupArr[vIdx].caption)
		}

		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			vElsfocusnext && vElsfocusnext.focus()
		},350);
	}
	const hdlKlikBack=async()=>{
		const vProsesBack = () => { uNavigate(-1); }

		if(uIsFormupdate) {
			if(await(Konfirm(
				"<strong>"+(uBahasaObj.pesan_formisupdate||"pesan_formisupdate")+"..</strong>"+
				"<BR><BR>"+(uBahasaObj.confirm_kembali||"confirm_kembali")+
				""))) vProsesBack();

			return;
		}
		vProsesBack();
	}
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			if(uIsFormprocess) return;

			_EV.preventDefault();
			hdlKlikSimpanmain();
		}
	}
	const hdlKlikSupplierlookup = () => {
		setInpDlglookup(uDatamainObj.nama_supplier||"");
		uDatamainObj.nama_supplier	= "";

		setDlglookupjenis("SUPPLIER");
		const vTmpArr	= uDatasupplierArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.word_supplier||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKDownSuppliercaption = (_EV) => {
		uDatamainObj.id_supplier = "0"
		if(_EV.keyCode !== 13) return;
		if((uDatamainObj.nama_supplier||"").trim()==="") { return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatasupplierArr.filter((vItems)=>
			vItems.caption.toUpperCase().includes((uDatamainObj.nama_supplier||"").toUpperCase()));
		
		if (vArrCariMaster.length === 1) {
			uDatamainObj.id_supplier 	= (vArrCariMaster[0].id);
			uDatamainObj.nama_supplier 	= (vArrCariMaster[0].caption);

			setEfekview(!uEfekview)
			document.getElementById("inpformitemnama").focus();
		} else {
			hdlKlikSupplierlookup();
		}
	}
	const hdlKlikKaslookup = () => {
		//setInpDlglookup(uDatamainObj.nama_gl_kas||"");
		uDatamainObj.nama_gl_kas	= "";
		uDatamainObj.id_gl_kas		= "0";

		setDlglookupjenis("KAS");
		const vTmpArr	= uKasGLArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_asalkas||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKlikReset=async()=>{
		if(!uIsFormupdate || uHTMLError500) { apiLoadinit(); return }

		const vKonfirm	=
			"<strong>"+(uBahasaObj.petunjuk_resetform||"petunjuk_resetform")+"</strong>.."+
			"<BR><BR>"+(uBahasaObj.confirm_reset||"confirm_reset")+
		"";
		if(await Konfirm(vKonfirm)) initResetmain();
	}
	const hdlKlikItemTambah = () => {
		uDlgforminputObj.header = "Tambah Item PEMBELIAN";
		setDlgformshow(true);
	}
	const hdlKlikItemHapus=async(_KEYIDX)=>{
		_KEYIDX = parseInt(_KEYIDX) || 0; if(_KEYIDX < 0) return;

		const vObjdx	= _KEYIDX;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_KEYIDX);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusitem||"caption_hapusitem")+": <B>"+(uDatatabelArr[vObjdx].nama_aset||"").toUpperCase()+"</B>"+
			"<br/>"+
			"<br/><b>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</b>"+
		"";

		if(await Konfirm(vConfirm)) initItemHapus(_KEYIDX);
	}
	const hdlKlikTabeledit = (_KEYIDX,_FIELDEDIT) => {
		_KEYIDX		= parseInt(_KEYIDX||"0");
		_FIELDEDIT	= _FIELDEDIT||"";

		initResetformitem();
		setTabeleditObj({editidx:_KEYIDX,editfield:_FIELDEDIT});

		const vIdx 		= _KEYIDX;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_KEYIDX);
		setTabelRowSelect(vRowIdx);

		let vElementID;
		let vValue;
		if(_FIELDEDIT.toUpperCase()==="QTY") {
			vElementID = "inptabelqty";
			vValue = (uDatatabelArr[vIdx].qty);
		} else if(_FIELDEDIT.toUpperCase()==="HARGA") {
			vElementID 	= "inptabelharga";
			vValue 		= (uDatatabelArr[vIdx].nilai_barang);
		} else {
			vElementID 	= "inptabeljenisid";
			vValue 		= (uDatatabelArr[vIdx].id_aset_jenis);
		}

		setTabeleditObj({
			editidx:_KEYIDX,
			editfield:_FIELDEDIT,
			editvalue: vValue,
		});
		setEfekview(!uEfekview)
		let vTimeout = setTimeout(function(e) {
			clearTimeout(vTimeout);
			document.getElementById(vElementID) 
				&& document.getElementById(vElementID).focus();
		},300);
	}
	const hdlKlikTabeleditmobile = (_KEYIDX) => {
		_KEYIDX = parseInt(_KEYIDX);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_KEYIDX);
		setTabelRowSelect(vRowIdx);

		uDlgforminputObj.header = "Edit Item: "+(uDatatabelArr[_KEYIDX].nama_aset).toUpperCase();
		uDlgforminputObj.keyidx = _KEYIDX;

		uDlgforminputObj.nama_aset 		= uDatatabelArr[_KEYIDX].nama_aset;
		uDlgforminputObj.jenisaset_id 	= uDatatabelArr[_KEYIDX].id_aset_jenis;
		uDlgforminputObj.jenisaset_caption = uDatatabelArr[_KEYIDX].nama_aset_jenis;
		uDlgforminputObj.qty 			= uDatatabelArr[_KEYIDX].qty;
		uDlgforminputObj.nilai_barang 	= uDatatabelArr[_KEYIDX].nilai_barang;
		setDlgformshow(true);
	}
	const hdlKlikTabelproses=(_KEYIDX,_FIELDEDIT)=>{
		//--PROSES_EDITNYA--/
		const vIdx 		= parseInt(_KEYIDX) || 0;
		if(vIdx < 0) 
			{ initItemDefault(); return; }

		const vValue = parseInt(uTabeleditObj.editvalue) || 0;
		if(_FIELDEDIT.toUpperCase()==="QTY") {
			if(vValue <= 0) {
				showToast((uBahasaObj.word_qty||"word_qty")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
				return;
			}

			uDatatabelArr[vIdx].qty 	= vValue;
		} else if(_FIELDEDIT.toUpperCase()==="HARGA") {
			if(vValue < 0) {
				showToast((uBahasaObj.caption_hargasatuan)+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
				return;
			}

			const vValueHarga = parseInt(uTabeleditObj.editvalue) || 0;
			uDatatabelArr[vIdx].nilai_barang 	= vValue;
		} else {
			const vArridx				= UFunc.getObjectIndeks(uDataasetjenisArr,vValue,"value")
			const vValueJenisCaption 	= uDataasetjenisArr[vArridx].caption;
			uDatatabelArr[vIdx].id_aset_jenis 	= vValue;
			uDatatabelArr[vIdx].nama_aset_jenis	= vValueJenisCaption;
		}
		//--END PROSES_EDITNYA--/
		initItemDefault();
	}
	const hdlKDownTabeledit=(_EV,_KEYIDX,_FIELDEDIT)=>{
		if(_EV.which !== 27 && _EV.which !== 13) return;

		_EV.preventDefault();
		if(_EV.which === 27) initItemDefault();
		else if(_EV.which === 13) hdlKlikTabelproses(_KEYIDX,_FIELDEDIT);
	}
	const hdlKlikFormjenislookup = () => {
		uDlgforminputObj.jenisaset_caption	= "";
		uDlgforminputObj.jenisaset_id		= 0;

		setDlglookupjenis("FORMJENIS");
		const vTmpArr	= uDataasetjenisArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_jenisaset||"").toUpperCase());
		setDlglookupshow(true);
	}
	const hdlKlikDlgformSimpan = () => {
		if((uDlgforminputObj.nama_aset||"").trim() === "") return;
		if((parseInt(uDlgforminputObj.qty)||0) <= 0) return;
		if((parseInt(uDlgforminputObj.jenisaset_id)||0) <= 0) return;
		if((parseFloat(uDlgforminputObj.nilai_barang)||0) <= 0) {
			showToast(uBahasaObj.caption_hargasatuan+" "+uBahasaObj.caption_invalid+" !");
			return;
		}

		const vKeyidx 	= uDlgforminputObj.keyidx===undefined 
			? -1 : parseInt(uDlgforminputObj.keyidx);
		//alert(vKeyidx); return;

		const vItemsObj	= {
			nama_aset 		: UFunc.capitalize(uDlgforminputObj.nama_aset||""),
			id_aset_jenis 	: uDlgforminputObj.jenisaset_id||"0",
			nama_aset_jenis : uDlgforminputObj.jenisaset_caption,
			qty 			: parseInt(uDlgforminputObj.qty)||0,
			nilai_barang 	: parseFloat(uDlgforminputObj.nilai_barang)||0
		}
		if(vKeyidx >= 0) {
			uDatatabelArr[vKeyidx] = vItemsObj;
			initSUMtotal();
			setDlgformshow(false);
			return;
		}
		uDatamainObj.items_arr = ([vItemsObj,...uDatatabelArr]);

		//uDlgforminputObj.header 	= "Tambah Item PEMBELIAN";
		uDlgforminputObj.nama_aset 	= "";
		uDlgforminputObj.jenisaset_id 	= "0";
		uDlgforminputObj.jenisaset_caption 	= "";
		uDlgforminputObj.qty 		= 0;
		uDlgforminputObj.nilai_barang= 0;
		console.log("(Beliasetmain - hdlKlikDlgformSimpan) vItemsObj => "+JSON.stringify(vItemsObj));

		if(isMobile)
			showToast(uBahasaObj.pesan_sukses,"SUKSES");
		else
			document.getElementById("inpformitemnama") &&
				document.getElementById("inpformitemnama").focus();
		setEfekview(!uEfekview);
	}
	const hdlKlikSimpanmain = async () => {
		if(!uIsFormupdate) return;

		//---VALIDASI--/
		let vElfocus = document.getElementById("inptanggal");
		if((uDatamainObj.tgl_beli||"")==="") {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.word_tanggal||"word_tanggal")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}

		const vTanggalDt 	= new Date(uDatamainObj.tgl_beli);

		const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
		if(vDateBeyond) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
			return;
		}
		const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
		if(vDateExceededBl) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
			return;
		}
		vElfocus = document.getElementById("inpsupplierid");
		if(!uIsSuppliernew) {
			if(parseInt(uDatamainObj.id_supplier||0) <= 0
				|| (uDatamainObj.nama_supplier||"") === ""
				) {
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.word_supplier||"word_supplier")+
					" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
				return;
			}
		} else {
			if((uDatamainObj.nama_supplier||"") === ""
				) {
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.word_supplier||"word_supplier")+
					" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
				return;
			}
		}
		vElfocus = document.getElementById("inpkasid");
		if(parseInt(uDatamainObj.id_gl_kas||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_asalkas||"caption_asalkas")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		vElfocus = document.getElementById("inpformitemnama");
		if(uDatatabelArr.length <= 0){
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.pesan_itemstillempty||"pesan_itemstillempty"));
			return;
		}
		//---END VALIDASI--/

		if(uDatamainObj.kategori !== "INVENTARIS") {
			if(await Konfirm(
				"<b>Anda Memilih Kategori BIAYA..</b>"+
				"<br/><br/>Kategori Biaya Berarti Seluruh Pembelian akan Langsung Dimasukkan Sebagai BIAYA, "+
				"TANPA Melalui Proses Penyusutan INVENTARIS.."+
				"<br/><br/><b>Apakah Anda Yakin</b> ?"+
				""
			)) initSimpanfinale();

			return;
		}

		initSimpanfinale();
	}
	//---END HANDEL--/

	//--INIT--/
	const initResetmain = () => {
		setDatamainObj({});
		setDataformObj({});
		setTabeleditObj({});
		setEfekview(!uEfekview)
	}
	const initResetformitem = () => {
		const vNamaitem = uDataformObj.nama_aset||"";
		setDataformObj({})
		uDataformObj.id_aset_jenis 	= uDataasetjenisArr[0].value;
		uDataformObj.nama_aset 		= vNamaitem
		setEfekview(!uEfekview)
	}
	const initItemHapus=(_KEYIDX)=>{
		_KEYIDX = parseInt(_KEYIDX) || 0; if(_KEYIDX < 0) return;

		const vObjdx	= _KEYIDX
		if(vObjdx >= 0) uDatatabelArr.splice(vObjdx,1);
		uDatamainObj.items_arr = uDatatabelArr;
		setTabelRowSelect(-1);

		setEfekview(!uEfekview)
	}
	const initItemDefault = () => {
		setTabeleditObj({}); 
		setEfekview(!uEfekview)
	}
	const setInitvalue = (_NAMAFIELD,_VALUE) => {
		uDatamainObj[_NAMAFIELD] = _VALUE;
		setEfekview(!uEfekview);
	}
	const setInititem = (_NAMAFIELD,_VALUE) => {
		uDataformObj[_NAMAFIELD] = _VALUE;
		setEfekview(!uEfekview);
	}
	const initSUMtotal = () => {
		const vSUMtotal = uDatatabelArr.reduce((Acc,Curr)=>{
			return Acc + ( parseInt(Curr.qty) * parseFloat(Curr.nilai_barang) )
		},0)

		uDatamainObj.nilai_bruto = vSUMtotal;
		uDatamainObj.nilai_netto = vSUMtotal;
	}
	const initSimpanfinale = async () => {
		const vTanggalDt 	= new Date(uDatamainObj.tgl_beli);
		const vDateisMundur = UFunc.DateIsMundur(vTanggalDt);
		const vSuppliernama	= uDatasupplierArr[UFunc.
			getObjectIndeks(uDatasupplierArr,uDatamainObj.id_supplier,"value")||0].caption;
		const vKasAsal		= uKasGLArr[UFunc.
			getObjectIndeks(uKasGLArr,uDatamainObj.id_gl_kas,"value")||0].caption;

		const vHTMLKonfirm	=
		"<div class='row'>"+
		"<div class='col-12 text-center'><strong>"+(uBahasaObj["menus_beliaset"]||"").toUpperCase()+"</strong></div>"+
		"<div class='col-12'><HR class='my-2' style='border:none;border-bottom:1px dotted gray;'/></div>"+
		((uDatamainObj.no_nota||"")!==""?
		"<div class='col-5'>"+(uBahasaObj.word_nonota||"word_nonota")+"</div>"+
		"<div class='col-7 fw-bolder' align='right'>"+(uDatamainObj.no_nota||"").toUpperCase()+"</div>"+
		""
		:""
		)+
		"<div class='col-5 '>"+(uBahasaObj.caption_tgltransaksi||"caption_tgltransaksi")+"</div>"+
		"<div class='col-7  fw-bolder' align='right'>"+UFunc.HariAngka(uDatamainObj.tgl_beli)+"</div>"+
		"<div class='col-3'>"+(uBahasaObj.word_supplier||"word_supplier")+"</div>"+
		"<div class='col-9 fw-bolder' align='right'>"+vSuppliernama+"</div>"+
		"<div class='col-5'>"+(uBahasaObj.caption_asalkas||"caption_asalkas")+"</div>"+
		"<div class='col-7 fw-bolder' align='right'>"+vKasAsal+"</div>"+

		"<div class='col-12'><HR class='my-2' style='border:none;border-bottom:1px dotted gray;'/></div>"+
		"<div class='col-5'>"+(uBahasaObj.caption_jmlitem||"caption_jmlitem")+"</div>"+
		"<div class='col-7 fw-bolder text-end'>"+
			UFunc.formatAngka(uDatatabelArr.length)+" "+(uBahasaObj.word_item||"word_item")+"</div>"+
		"<div class='col-5'>"+(uBahasaObj.caption_totalnetto||"caption_totalnetto")+"</div>"+
		"<div class='col-7 fw-bolder text-end'>Rp"+UFunc.formatAngka(uDatamainObj.nilai_netto)+"</div>"+
		"<div class='col-5'>"+(uBahasaObj.word_kategori||"word_kategori")+"</div>"+
		"<div class='col-7 fw-bolder text-end'>"+(uDatamainObj.kategori||"").toUpperCase()+"</div>"+

		"<div class='col-12'><HR class='my-2' style='border:none;border-bottom:1px dotted gray;'/></div>"+
		"<div class='col-5'>"+(uBahasaObj.caption_nilaibayar||"caption_nilaibayar")+"</div>"+
		"<div class='col-7 fw-bolder text-primary text-end'>Rp"+UFunc.formatAngka(uDatamainObj.nilai_bayar)+"</div>"+
		"<div class='col-5'>"+(uBahasaObj.caption_sisahutang||"caption_sisahutang")+"</div>"+
		"<div class='col-7 fw-bolder text-end"+
			(parseInt(uDatamainObj.nilai_hutang||0)>0?" text-danger":"")+"'>Rp"+UFunc.formatAngka(uDatamainObj.nilai_hutang)+"</div>"+

		(vDateisMundur?
		"<div class='col-12'><HR class='my-2' style='border:none;border-bottom:1px dotted gray;'/></div>"+
		"<div class='col-12 px-5 fst-italic text-warning'>"+
			(uBahasaObj.pesan_dateismundur||"pesan_dateismundur")+"</div>"+
		""
		:""
		)+
		"<div class='col-12 p-3 px-4 fw-bolder text-center text-success'>"+(uBahasaObj.confirm_simpan||"confirm_simpan")+"</div>"+

		//"<div class='text-black'>"+(uDatamainObj.kategori)+"</div>"+
		"</div>"+
		"";

		if(await(Konfirm(vHTMLKonfirm))) apiSimpanmain();
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

		return (
		<>
		<CRow className="align-items-stretch">
			<CCol md="6" className="mb-3">
				<CCard className="classcardbg h-100" id="idcardnota">
				<CCardHeader className="py-1">
					<small>Nota</small>
				</CCardHeader>
				<CCardBody>{contentNota()}</CCardBody>
				</CCard>
			</CCol>
			<CCol className="mb-3">
				<CCard className="classcardbg h-100" id="idcardsupplier">
				<CCardHeader className="py-1">
					<small>Supplier</small>
				</CCardHeader>
				<CCardBody>{contentSupplier()}</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CRow className="">
			<CCol>
			<CCard className="classcardbg mb-3">{contentDatatabel()}</CCard>
			</CCol>
		</CRow>

		<CRow className="align-items-stretch">
			<CCol md="6" className="mb-3">
			<CCard className="classcardbg h-100">
			<CCardBody>{contentFooter1()}</CCardBody>
			</CCard>
			</CCol>

			<CCol className="mb-3">
			<CCard className="classcardbg h-100">
			<CCardBody>{contentFooter2()}</CCardBody>
			</CCard>
			</CCol>
		</CRow>
		</>
		)//>
	}
	const contentNota = () => {
		if(uHTMLError500) return "";

		return (
		<>
		<CRow className="my-2">
		<CCol xs="4" className="text-info">
			<span className="d-none d-md-block">{uBahasaObj.caption_tgltransaksi||"caption_tgltransaksi"}</span>
			<small className="d-md-none">{uBahasaObj.caption_tgltransaksi||"caption_tgltransaksi"}</small>
		</CCol>
		<CCol className="">
			<CFormInput size="sm" type="date"
				value={uDatamainObj.tgl_beli}
				onChange={(e)=>setInitvalue("tgl_beli",e.target.value)}
				id="inptanggal"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4">
			<span className="d-none d-md-block">{uBahasaObj.word_nonota||"word_nonota"}</span>
			<small className="d-md-none">{uBahasaObj.word_nonota||"word_nonota"}</small>
		</CCol>
		<CCol className="text-end">
			<CFormInput size="sm"
				maxLength={15}
				className="text-uppercase"
				value={uDatamainObj.no_nota||""}
				onChange={(e)=>setInitvalue("no_nota",e.target.value)}
				id="inpnonota"/>
		</CCol>
		</CRow>
		</>
		)//>
	}
	const contentSupplier = () => {
		if(uHTMLError500) return "";

		return (
		<>
		<CRow className="my-2">
			<CCol xs="12" lg="4" className="text-info">
				{(!uIsSuppliernew) ? (
				<span className="text-info">{uBahasaObj.word_supplier||"word_supplier"}</span>
				): (
				<span className="text-danger">{uBahasaObj.word_supplier||"word_supplier"} {uBahasaObj.word_baru||"word_baru"}</span>
				)}
			</CCol>
			<CCol>
			{(!uIsSuppliernew) ? (
			<>
				<CInputGroup size="sm">
					<CFormInput size="sm"
						placeholder="--Masukkan Keyword SUPPLIER.."
						value={uDatamainObj.nama_supplier||""}
						onChange={(e)=>setInitvalue("nama_supplier",e.target.value)}
						onKeyDown={(e)=>hdlKDownSuppliercaption(e)}
						id="inpsuppliercaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikSupplierlookup()}
						id="btnsupplierlookup">
					<CIcon icon="cilMagnifyingGlass"/>
					</CButton>

					<CTooltip content="Toggle Supplier Baru">
					<CButton size="sm" color="dark" 
						className="border-start border-start-white"
						onClick={()=>{setSuppliernew(true);}}
						id="btnsupplierlamatoggle">
					<CIcon icon="cibAddthis"/>
					</CButton>
					</CTooltip>
				</CInputGroup>
			</>
			) : (
			<>
				<CInputGroup size="sm">
					<CFormInput size="sm" maxLength={100}
						value={uDatamainObj.nama_supplier||""}
						onChange={(e)=>setInitvalue("nama_supplier",e.target.value)}
						id="inpsupplierbaru"/>
				<CInputGroupText className="p-0 bg-danger">
					<CTooltip content="Toggle Supplier LAMA">
					<CButton size="sm" color="danger"
						onClick={()=>setSuppliernew(false)}
						id="btnsupplierbarutoggle">
					<CIcon icon="cilTouchApp"/>
					</CButton>
					</CTooltip>
				</CInputGroupText>
				</CInputGroup>
			</>
			)}
			</CCol>
		</CRow>
		</>
		)//>
	}
	const contentDatatabel = () => {
		if(uHTMLError500) return "";

		return (
			<>
			<CCardHeader className="d-flex justify-content-between py-1">
				<CButton size="sm" color="secondary"
					onClick={()=>hdlKlikItemTambah()}
					id="btnTambahitem">
					<CIcon icon="cibAddthis" className="me-1"/>Tambah Item
				</CButton>

				<small className="text-muted">{uBahasaObj.caption_itemaset||"caption_itemaset"}</small>
			</CCardHeader>

			<CCardBody className="px-0">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
				<thead>
					<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
					<th width="5%" className="px-1">No</th>
					<th className="px-1">
					<CRow className="mx-1">
						<CCol md="4" className="text-start">{uBahasaObj.caption_namabarang||"caption_namabarang"}</CCol>
						<CCol md="3" className="text-start">{uBahasaObj.caption_jenisaset||"caption_jenisaset"}</CCol>
						<CCol md="1" className="">{uBahasaObj.word_qty||"QTY"}</CCol>
						<CCol md="2" className="">{uBahasaObj.caption_hargasatuan||"caption_hargasatuan"} (Rp)</CCol>
						<CCol md="2" className="">{uBahasaObj.word_total||"word_total"}(Rp)</CCol>
					</CRow>
					</th>
					</tr>
				</thead>

				<tbody>
				{uDatatabelArr.map((vItems,vKeys)=>{
					const {
						nama_aset,id_aset_jenis,nama_aset_jenis,
						qty,nilai_barang
					} = vItems;

					const vCaptionUpper		= (nama_aset||"").toUpperCase();
					const vCaptionjenis 	= (nama_aset_jenis||"")==="" ? UFunc.renderHTML("&#9901;")
						: UFunc.renderHTML("<small className='text-uppercase'>"+(nama_aset_jenis||"")+"</small>");

					const vEditIdx		= uTabeleditObj.editidx || "0";
					const vEditField	= uTabeleditObj.editfield || "";

					return (
					<tr id={"idtr"+vKeys} 
						className={"text-end align-top"+(uTabelRowSelect===(vKeys)?"classrowselect":"")}
						key={vKeys}>
					<td className="px-1 pe-0">{vKeys+1}.</td>
						
					<td className="px-1 ps-0">
					<CRow className="mx-0">
						<CCol md="4" className="px-1 text-start d-flex justify-content-between">
							<div className="text-info text-capitalize">{(nama_aset||"")}</div>

							<CTooltip content={(uBahasaObj.caption_hapusitem||"caption_hapusitem")+": "+vCaptionUpper}>
							<CLink onClick={()=>hdlKlikItemHapus(vKeys)}
								className="d-none d-md-block classcursorpointer classikontabel classikonhapus"/>
							</CTooltip>

							<CDropdown className="d-md-none">
							<CDropdownToggle caret={false} size="sm" className="p-0"
								color="transparent">
								<CIcon icon="cilOptions" className="classikoncoreui" height={23}/>
							</CDropdownToggle>
							<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikTabeleditmobile(vKeys)}>
								<CIcon className="classikonedit ms-0 me-2 align-middle" height={25}/>
								{(uBahasaObj.caption_edititem || "Edit Item")}
							</CDropdownItem>
							<CDropdownItem onClick={()=>hdlKlikItemHapus(vKeys)}>
								<CIcon className="classikonhapus ms-0 me-2 align-middle" height={25}/>
								{uBahasaObj.caption_hapusitem||"Hapus Item"}
							</CDropdownItem>
							</CDropdownMenu>
							</CDropdown>
						</CCol>

						<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

						<CCol xs="3" className="px-1 d-md-none text-start">{uBahasaObj.caption_jenisaset}</CCol>
						<CCol xs="9" md="3" className="px-1 text-md-start">
							{(parseInt(vEditIdx)===vKeys && vEditField === "JENIS") ? (
							<CInputGroup size="sm">
								<CFormSelect size="sm"
									value={uTabeleditObj.editvalue||"0"}
									onChange={(e)=>{uTabeleditObj.editvalue=(e.target.value); setEfekview(!uEfekview)}}
									onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,"JENIS")}
									className="classfontsmaller"
									id="inptabeljenisid">
								{uDataasetjenisArr.map((vItemsAset,vKeyAset)=>{
									return (
									<option value={vItemsAset.value} 
										key={vKeyAset}>{vItemsAset.caption}</option>
									)
								})}
								</CFormSelect>
								<CInputGroupText className="p-0 bg-dark">
								<CButton size="sm"
									color="dark"
									onClick={()=>hdlKlikTabelproses(vKeys,"JENIS")} 
									id="btntabeljenis">
									<CIcon icon="cilCheckAlt"/>
								</CButton>
								</CInputGroupText>
							</CInputGroup>
							) : (
							<CLink 
								onClick={()=>hdlKlikTabeledit(vKeys,"JENIS")}
								className="text-dark classcursorpointer text-decoration-none">
								{vCaptionjenis}
							</CLink>
							)}
						</CCol>

						<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_qty} (s)</CCol>
						<CCol xs="7" md="1" className="px-1 px-md-0 text-md-center">
							{(parseInt(vEditIdx)===vKeys && vEditField === "QTY") ? (
							<CInputGroup size="sm">
								<CFormInput size="sm" type="number"
									min="1"
									value={uTabeleditObj.editvalue||"0"}
									onChange={(e)=>{uTabeleditObj.editvalue=(e.target.value);setEfekview(!uEfekview)}}
									onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,"QTY")}
									onFocus={(e)=>e.target.select()}
									id={"inptabelqty"}/>
								<CInputGroupText className="p-0 bg-dark">
								<CButton size="sm"
									color="dark"
									onClick={()=>hdlKlikTabelproses(vKeys,"QTY")} 
									id="btntabelqty">
									<CIcon icon="cilCheckAlt"/>
								</CButton>
								</CInputGroupText>
							</CInputGroup>
							) : (
							<CLink 
								onClick={()=>hdlKlikTabeledit(vKeys,"QTY")}
								className="text-dark classcursorpointer text-decoration-none">
								<b>{UFunc.formatAngka(qty||0)}</b>
								<span className="ms-1">UNIT</span>
							</CLink>
							)}
						</CCol>

						<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_hargasatuan} (Rp)</CCol>
						<CCol xs="7" md="2" className="px-1">
							{(parseInt(vEditIdx)===vKeys && vEditField === "HARGA") ? (
							<CInputGroup size="sm">
								<NumberFormat 
									value={uTabeleditObj.editvalue||"0"}
									displayType={"text"} 
									thousandSeparator={"."} 
									decimalSeparator={","}
									onValueChange={(values) => {uTabeleditObj.editvalue=(values.value)}}
									renderText={values => (
										<CFormInput size="sm"
											className="text-end"
											value={values}
											onChange={(e)=>{uTabeleditObj.editvalue=(e.target.value);setEfekview(!uEfekview)}}
											onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,"HARGA")}
											onFocus={(e)=>e.target.select()}
										id={"inptabelharga"}/>
									)}
									prefix={""}/>
								<CInputGroupText className="p-0 bg-dark">
								<CButton size="sm"
									color="dark"
									onClick={()=>hdlKlikTabelproses(vKeys,"HARGA")} 
									id="btntabelharga">
									<CIcon icon="cilCheckAlt"/>
								</CButton>
								</CInputGroupText>
							</CInputGroup>
							) : (
							<CLink 
								onClick={()=>hdlKlikTabeledit(vKeys,"HARGA")}
								className="text-dark classcursorpointer text-decoration-none">
								{UFunc.formatAngka(nilai_barang||0)}
							</CLink>
							)}
						</CCol>

						<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

						<CCol xs="5" className="px-1 d-md-none text-start text-primary">{uBahasaObj.word_total} (Rp)</CCol>
						<CCol xs="7" md="2" className="px-1 text-primary">
							{UFunc.formatAngka((parseInt(nilai_barang)||0) * (parseInt(qty)||0))}
						</CCol>
						</CRow>
					</td>
					</tr>
					)
				})}
				</tbody>

				{uDatatabelArr.length > 0 && (
				<tfoot className="text-center fw-bolder bg-dark text-white">
				<tr className="align-top">
					<td className="px-1"/>
					<td className="px-1">
						<CRow className="mx-0">
						<CCol xs="5" md="9">{(uBahasaObj.word_jumlah||"Jumlah").toUpperCase()} (Rp)</CCol>
						<CCol xs="7" md="3" className="text-end px-1">
							{UFunc.formatAngka(uDatamainObj.nilai_bruto)}
						</CCol>
						</CRow>
					</td>
					</tr>
				</tfoot>
				)}
			</table>
			</CCardBody>
			</>
		)//>
	}
	const contentFooter1 = () => {
		if(uHTMLError500) return "";

		return (
			<>
			<CRow className="my-2 d-none">
			<CCol xs="4">
				<span className="d-none d-md-block">{uBahasaObj.caption_totalnetto||"caption_totalnetto"}</span>
				<small className="d-md-none">{uBahasaObj.caption_totalnetto||"caption_totalnetto"}</small>
			</CCol>
			<CCol className="text-end fw-bolder text-primary">
				<big>Rp{UFunc.formatAngka(uDatamainObj.nilai_netto)}</big>
			</CCol>
			</CRow>

			<div className="my-3 d-none classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4">
				<span className="d-none d-md-block">{uBahasaObj.caption_kategoriasetx||"Kategori Aset"}</span>
				<small className="d-md-none">{uBahasaObj.caption_kategoriasetx||"Kategori Aset"}</small>
			</CCol>
			<CCol className="text-end">
				<CFormSelect
					size="sm"
					value={uDatamainObj.kategori||"INVENTARIS"}
					onChange={(e)=>setInitvalue("kategori",e.target.value)}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"inpnilaibayar")}
					disabled={(uTokenObj.userhak!=="FREEROLE")}
					id="inpkategori">
					<option value="INVENTARIS">{(uBahasaObj.caption_kategoriinventaris||"caption_kategoriinventaris").toUpperCase()}</option>
					<option value="BIAYA">{(uBahasaObj.caption_kategoribiaya||"caption_kategoribiaya").toUpperCase()}</option>
				</CFormSelect>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="fw-bolder">
				<span className="d-none d-md-block">{uBahasaObj.caption_asalkas||"Asal Kas"}</span>
				<small className="d-md-none">{uBahasaObj.caption_asalkas||"Asal Kas"}</small>
			</CCol>
			<CCol className="text-end">
				<CInputGroup size="sm">
				<CFormInput size="sm"
					value={uDatamainObj.nama_gl_kas||""}
					placeholder={"--"+uBahasaObj.word_pilih+" "+uBahasaObj.caption_asalkas+".."}
					readOnly={true}
					onClick={()=>hdlKlikKaslookup()}
				id="inpkascaption"/>
				<CInputGroupText className="p-0 bg-dark">
					<CButton size="sm"
						color="dark"
						onClick={()=>hdlKlikKaslookup()}
						id="btnkaslookup">
					<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
				</CInputGroupText>
				</CInputGroup>
			</CCol>
			</CRow>
			</>
		)//>
	}
	const contentFooter2 = () => {
		if(uHTMLError500) return "";

		return (
			<>
			<CRow className="my-2">
			<CCol xs="4" className="fw-bolder">
				<span className="d-none d-md-block">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</span>
				<small className="d-md-none">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</small>
			</CCol>
			<CCol className="text-end">
				<NumberFormat 
					value={uDatamainObj.nilai_bayar||"0"}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => setInitvalue("nilai_bayar",values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=>setInitvalue("nilai_bayar",e.target.value)}
							onFocus={(e)=>e.target.select()}
						onKeyDown={(e)=>{
							if(parseInt(uDatamainObj.nilai_hutang||0) <= 0)
								UFunc.hdlKeyDownInput(e,"btnSimpanmain")
						}}
						id="inpnilaibayar"/>
					)} 
					prefix={isMobile? '': 'Rp'}/>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4">
				<span className="d-none d-md-block">{uBahasaObj.caption_sisahutang||"caption_sisahutang"}</span>
				<small className="d-md-none">{uBahasaObj.caption_sisahutang||"caption_sisahutang"}</small>
			</CCol>
			<CCol className={"text-end fw-bolder"+(uDatamainObj.nilai_hutang>0?" text-danger":"")}>
				Rp{UFunc.formatAngka(uDatamainObj.nilai_hutang)}
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4">
				<span className="d-none d-md-block">{uBahasaObj.caption_tgljt||"caption_tgljt"}</span>
				<small className="d-md-none">{uBahasaObj.caption_tgljt||"caption_tgljt"}</small>
			</CCol>
			<CCol className="text-end">
				{parseInt(uDatamainObj.nilai_hutang) > 0 ? (
				<CFormInput size="sm" type="date"
					value={uDatamainObj.tgl_jt||""}
					onChange={(e)=>setInitvalue("tgl_jt",e.target.value)}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpanmain")}
					id="inptgljt"/>
				) : (
				<CIcon icon="cilInfinity" className="py-1" height={25}/>
				)}
			</CCol>
			</CRow>
			</>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>);//>

		return (
		<CForm>
			<CRow className="my-2">
			<CCol xs="4" className="">{uBahasaObj.caption_namaaset||"caption_namaaset"}</CCol>
			<CCol className="text-end">
				<CFormInput size="sm"
					maxLength={100}
					value={uDlgforminputObj.nama_aset||""}
					onChange={(e)=>{uDlgforminputObj.nama_aset=e.target.value;setEfekview(!uEfekview)}}
					className="text-capitalize"
					id="inpformitemnama"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="">{uBahasaObj.caption_jenisaset||"caption_jenisaset"}</CCol>
			<CCol className="text-end">
				<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					placeholder="--Pilih Jenis Aset"
					value={uDlgforminputObj.jenisaset_caption||""}
					onChange={(e)=>{uDlgforminputObj.jenisaset_caption=e.target.value;setEfekview(!uEfekview)}}
					onClick={()=>hdlKlikFormjenislookup()}
					id="inpformitemjeniscaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikFormjenislookup()}
					id="btnformitemlookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4" className="">{uBahasaObj.word_qty||"word_qty"}</CCol>
			<CCol xs="8" md="5" lg="4" className="text-end">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					className="text-end"
					value={uDlgforminputObj.qty||0}
					onChange={(e)=>{uDlgforminputObj.qty=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>e.target.select()}
					id="inpformitemqty"/>
				<CInputGroupText className="fw-bolder">UNIT</CInputGroupText>
				</CInputGroup>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="">
				{uBahasaObj.caption_hargasatuan||"caption_hargasatuan"}
				{isMobile && (<span className="ms-1">(Rp)</span>)}
			</CCol>
			<CCol xs="8" md="5" className="text-end">
				<NumberFormat 
					displayType={'text'} 
					value={uDlgforminputObj.nilai_barang||0}
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values)=>{uDlgforminputObj.nilai_barang=values.value;}}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=>{uDlgforminputObj.nilai_barang=e.target.value;setEfekview(!uEfekview)}}
							onFocus={(e)=>e.target.select()}
							onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpformitemharga"/>
					)} 
					prefix={isMobile ? "": "Rp"}/>
			</CCol>
			</CRow>
		</CForm>
		)//>
	}
	//--END CONTENT--/

	//--API--/
	const apiLoadinit = () => {
		uFuncname = apiLoadinit.name;
		setHTMLError500();

		const vKasGLDefaultArr	= JSON.parse(localStorage.getItem("kasgllist"));
		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_beliaset/ld_init";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setIsFormprocess(false);
				let vTmpObj = [];
				if(output_string.supplierobject) {
					vTmpObj = JSON.parse(output_string.supplierobject);
					setDatasupplierArr(vTmpObj);
				}
				if(output_string.asetjenisobject) {
					vTmpObj = JSON.parse(output_string.asetjenisobject);
					setDataasetjenisArr(vTmpObj);
				}
				let vTambahanKasArr = [];
				if(output_string.kastambahanobject) {
					vTambahanKasArr = JSON.parse(output_string.kastambahanobject);
				}
				setKasGLArr([...vKasGLDefaultArr,...vTambahanKasArr]);
			} else if(output_string.info) {
				setIsFormprocess(false);
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiSimpanmain = () => {
		uFuncname = apiSimpanmain.name;
		setLoading(true); setIsFormprocess(true);

		const vDATAS	= JSON.stringify({
			send_datalist : JSON.stringify(uDatamainObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_beliaset/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false); setIsFormprocess(false);
			if(output_string.sukses) {
				let vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				if(output_string.pesankhusus) vPesan = output_string.pesankhusus;
				showToast(vPesan,"SUKSES");
				initResetmain();

				document.getElementById("inptanggal").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false); setIsFormprocess(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);
		return() => {
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	/*React.useEffect(()=>{
		const vHeightnota 		= uElCardnota && uElCardnota.offsetHeight;
		const vHeightsupplier	= uElCardsupplier && uElCardsupplier.offsetHeight;

		if(vHeightnota > vHeightsupplier) {
			uElCardsupplier && (uElCardsupplier.style.height = vHeightnota+"px");
		} else {
			uElCardnota && (uElCardnota.style.height = vHeightsupplier+"px");
		}
	},[uElCardnota,uElCardsupplier]);
	React.useEffect(()=>{
		const vHeightKI = uElCardfooterkiri && uElCardfooterkiri.offsetHeight;
		const vHeightKA	= uElCardfooterkanan && uElCardfooterkanan.offsetHeight;

		if(vHeightKI > vHeightKA) {
			uElCardfooterkanan && (uElCardfooterkanan.style.height = vHeightKI+"px");
		} else {
			uElCardfooterkiri && (uElCardfooterkiri.style.height = vHeightKA+"px");
		}
	},[uElCardfooterkiri,uElCardfooterkanan]);//-*/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});

		apiLoadinit();
		
		return() => {
			hdlToggleDlglookup();setDlgformshow(false);
			setDataformObj({});
			setDatamainObj({});
			setDataDlglookupArr([]);
			setDatasupplierArr([]);
			setDataasetjenisArr([]);
			setKasGLArr([]);//-*/
		}		
	},[]);
	React.useEffect(()=>{
		if(!uISDlgformshow) {
			setIsFormprocess(false);
			setDlgforminputObj({}); 
			return 
		}

		setIsFormprocess(true);
		const vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(!isMobile) 
				document.getElementById("inpformitemnama") &&
					document.getElementById("inpformitemnama").focus();
		},350)
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uIsDlglookupshow) {
			setIsFormprocess(false);
			setInpDlglookup("")
			setDataDlglookupArr([]); return;
		}
		setIsFormprocess(true);
	},[uIsDlglookupshow]);
	React.useEffect(()=>{
		if(uDataasetjenisArr.length <= 0) return;
		if(uDataformObj.id_aset_jenis === undefined) {
			uDataformObj.id_aset_jenis = uDataasetjenisArr[0].value;
			return;
		}
		const vIdx = UFunc.getObjectIndeks(uDataasetjenisArr,uDataformObj.id_aset_jenis,"value");
		if(vIdx < 0) {
			uDataformObj.nama_aset_jenis = (uDataasetjenisArr[0].caption);
			return;
		}
		uDataformObj.nama_aset_jenis = (uDataasetjenisArr[vIdx].caption);
	},[uDataasetjenisArr,uDataformObj.id_aset_jenis]);

	//--HEADER--/
	React.useEffect(()=>{
		uDatamainObj.id_supplier = "0";
		uDatamainObj.nama_supplier = "";
		if(uIsSuppliernew) {
			document.getElementById("inpsupplierbaru") &&
				document.getElementById("inpsupplierbaru").focus()
		}
		setEfekview(!uEfekview)
	},[uIsSuppliernew]);
	React.useEffect(()=>{
		if(uDatamainObj.tgl_beli === undefined)
			uDatamainObj.tgl_beli = UFunc.DbDate();
		if(uDatamainObj.kategori === undefined)
			uDatamainObj.kategori = "INVENTARIS"
	},[uDatamainObj,
	uDatamainObj.tgl_beli,
	uDatamainObj.kategori
	]);
	React.useEffect(()=>{
		//console.log("(Beliasetmain - [setFormupdate]) "+JSON.stringify(uDatamainObj))
		if(uDatatabelArr.length > 0
			|| (uDatamainObj.no_nota||"") !== ""
			|| (uDatamainObj.tgl_beli||UFunc.DbDate()) !== UFunc.DbDate()
			|| parseInt(uDatamainObj.id_supplier||0) > 0
			|| parseInt(uDatamainObj.id_gl_kas||0) > 0 
			|| parseInt(uDatamainObj.nilai_bruto||0) > 0
			|| parseInt(uDatamainObj.nilai_bayar||0) > 0
			|| (uDatamainObj.kategori||"INVENTARIS") !== "INVENTARIS"
			|| (uDatamainObj.tgl_jt||"") !== ""
			) setFormupdate(true);
		else setFormupdate(false);
	},[uDatamainObj.tgl_beli,
	uDatamainObj.no_nota,
	uDatamainObj.id_supplier,
	uDatamainObj.id_gl_kas,
	uDatatabelArr.length,
	uDatamainObj.nilai_bruto,
	uDatamainObj.kategori,
	uDatamainObj.nilai_bayar,
	uDatamainObj.tgl_jt
	]);
	React.useEffect(()=>{
		initSUMtotal();
		setEfekview(!uEfekview);
	},[uDatatabelArr, uDatatabelArr.length, uTabeleditObj]);
	/*React.useEffect(()=>{
		uDatamainObj.nilai_netto = parseFloat(uDatamainObj.nilai_bruto)||0;
		setEfekview(!uEfekview);
	},[uDatamainObj.nilai_bruto]);//-*/
	React.useEffect(()=>{
		if((parseInt(uDatamainObj.nilai_bayar)||0) > 0 && (parseInt(uDatamainObj.nilai_netto)||0) > 0) 
			if((parseInt(uDatamainObj.nilai_bayar)||0) > (parseInt(uDatamainObj.nilai_netto)||0) ) {
				uDatamainObj.nilai_bayar = (uDatamainObj.nilai_netto);return;
			}//-*/

		const vHutang	= (parseInt(uDatamainObj.nilai_netto)||0) - (parseInt(uDatamainObj.nilai_bayar)||0);
		uDatamainObj.nilai_hutang = (vHutang>0 ? vHutang : 0);

		setEfekview(!uEfekview);
	},[uDatamainObj.nilai_bayar,uDatamainObj.nilai_netto]);
	//--END HEADER--/
	//---END EFFECT---/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	
	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="px-3 classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={() => hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="me-2 align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_beliaset||"menus_beliaset").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderToggler onClick={() => hdlKlikReset()}>
				<CIcon className="classikonreset align-middle" height={30}/>
			</CHeaderToggler>
			<CHeaderNav>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1">
			<CContainer fluid className="px-1">
			<CForm className="px-lg-5">{contentMain()}</CForm>
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={uIsFormprocess}
				onClick={()=>hdlKlikSimpanmain()}
				id="btnSimpanmain">Simpan [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialogform
			options={{}}
			show={uISDlgformshow}
			toggle={()=>setDlgformshow(false)}
			onSimpan={()=>hdlKlikDlgformSimpan()}
			dialogHeader={uDlgforminputObj.header||""}
			renderContent={contentDlgform()}
			id="iddialogform"/>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDlglookupheader||"uDlglookupheader"}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>
		</>
	)//>
}

export default Beliasetmain;
