import React from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CFooter,
	CForm,CFormInput,CInputGroup,CButton,CFormSelect,
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CDropdown,
	CDropdownToggle,
	CDropdownHeader,
	CDropdownMenu,
	CDropdownItem,
	CDropdownDivider,
	CCollapse,
	CImage,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import { 
	MyDialogview,
	MyDialogform,
	MyPagination,
	MyPage500,
	MyCardPasien,
	MyProfileDetil,
	MyDialoglookupapi,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     		= require('../../../package.json');
const Pasienrekammedis 	= (props) => {
	const uComponentname 	= "Pasienrekammedis";//.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 
	const { id } 			= useParams(); 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uPasienstateURL	= useSelector(state => state.gStatePasien);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	const uLayananArr		= JSON.parse(localStorage.getItem("layananlist")||"[]");
	const uISAllowedhapus	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted;
	const uMaxData			= 10;

	const uIDPasien								= parseInt(id) || 0;
	const [uHTMLError500,setHTMLError500]		= React.useState(null);
	const [uPasienRefresh,setPasienRefresh]		= React.useState(false);
	const [uFirstload,setFirstload]				= React.useState("YA");
	const [uTimeElapsed,setTimeElapsed]			= React.useState(0);
	const [uItemloadingID,setItemloadingID]		= React.useState(0);

	const [uLayananID,setLayananID]				= React.useState(uKeywordObj.kwd_rmlayananid||0);
	const [uDatamainArr,setDatamainArr]			= React.useState([]);
	const [uRowmainselect,setRowmainselect]		= React.useState(-1);
	const [uJmldatamain,setJmldatamain]			= React.useState(0);
	const [uIDmainpilih,setIDmainpilih]			= React.useState(0);
	const [uJmlhalmain,setJmlhalmain]			= React.useState(1);
	const [uPageactivemain,setPageactivemain]	= React.useState(1);
	const [uISMainloading,setMainloading]		= React.useState(false);
	const [uBodyInit,setBodyInit]				= React.useState(false);
	const [uMainError500,setMainError500]		= React.useState(null);

	const [uDatareminderArr,setDatareminderArr]			= React.useState([]);
	const [uRowreminderselect,setRowreminderselect]		= React.useState(-1);
	const [uJmldatareminder,setJmldatareminder]			= React.useState(0);
	const [uIDreminderpilih,setIDreminderpilih]			= React.useState(0);
	const [uJmlhalreminder,setJmlhalreminder]			= React.useState(1);
	const [uPageactivereminder,setPageactivereminder]	= React.useState(1);
	const [uISReminderloading,setReminderloading]		= React.useState(false);
	const [uReminderError500,setReminderError500]		= React.useState(null);

	//---PAGINATION_DIATUR_BACKEND--/
	const uTabelSelectObj 		= useSelector(state => state.gInitRMSelected) || {};
	const uIsInitbefore 		= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//---END PAGINATION_DIATUR_BACKEND--*/

	//---DIALOGVIEW--/
	const [uIsShowDlgview,setShowDlgview]		= React.useState(false)
	const [uDlgHeaderview,setDlgHeaderview]		= React.useState("")
	//---END DIALOGVIEW--/

	//---DIALOGFORM--/
	const [uIsShowDlgform,setShowDlgform]		= React.useState(false)
	const [uDlgformObj,setDlgformObj]			= React.useState({})
	//---END DIALOGFORM--/

	//---LOOKUPAPI--/
	const [uISLookupApishow,setLookupApishow]	= React.useState(false)
	const [uLookupApiObj,setLookupApiObj]		= React.useState({})
	const [uISLookuptrigger,setLookuptrigger]	= React.useState(false);
	//---END LOOKUPAPI--/

	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlKDownDocument = (_EV) => {}
	const hdlKlikReset = () => { 
		if(uHTMLError500) setPasienRefresh(!uPasienRefresh);

		//--PAGINATION_DIATUR_BACKEND--/
		setLayananID(0);
		uKeywordObj.kwd_rmlayananid = undefined; 
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

		setJmldatamain(0);
		setDatamainArr([]);
		setIDmainpilih(0);
		setRowmainselect(-1);
		setPageactivemain(1);
		//--END PAGINATION_DIATUR_BACKEND--*/

		apiLoaddata();
	}
	const hdlChangeLayanan = (_VALUE) => {
		const vLayananID = parseInt(_VALUE)||0;
		setLayananID(vLayananID);

		uKeywordObj.kwd_rmlayananid = vLayananID <= 0 ? undefined : vLayananID; 
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

		//--PAGINATION_DIATUR_BACKEND--/
		setJmldatamain(0);
		setDatamainArr([]);
		setIDmainpilih(0);
		setRowmainselect(-1);
		setPageactivemain(1);
		//--END PAGINATION_DIATUR_BACKEND--*/

		apiLoadrekammedis();
	}
	const hdlKlikItemcollapse = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		const vObjidx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabelmain","idtr"+_IDTABEL);
		setRowmainselect(vRowIdx);

		apiLoadItem(_IDTABEL);
	}
	const hdlKlikFoto = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0
		if(_IDTABEL <= 0) return

		const vObjidx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vFotoArr 	= uDatamainArr[vObjidx].foto_arr || [];
		
		setIDmainpilih(_IDTABEL)
		setDlgHeaderview(uBahasaObj.caption_fotoperiksa||"caption_fotoperiksa")
		if(vFotoArr.length > 0) 
			{ setShowDlgview(true); return }

		apiLoadfoto(_IDTABEL)
	}
	const hdlToggleDlgview = () => { setShowDlgview(false) }
	const hdlKlikDetil = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		setLoading(true);
		const vTabelinitselect = {
			initID: _IDTABEL,
			initPage: uPageactivemain,
		}
		uDispatch({type: "set", gInitRMSelected: vTabelinitselect}); 

		uNavigate("/klinik/pasien/detilrekmed/"+_IDTABEL);
	}
	const hdlKlikSwap = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return

		const vObjidx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowidx	= UFunc.getTabelRowIndeks("idtabelmain","idtr"+_IDTABEL);
		setIDmainpilih(_IDTABEL);
		setRowmainselect(vRowidx);

		const vDOMpasien	= document.getElementById("idcardnamapasien");

		uDlgformObj.dlg_jenis		= "DLGSWAP";
		uDlgformObj.dlg_header		= "Pilih Target Transfer Pasien";
		uDlgformObj.id_tabel		= _IDTABEL;
		uDlgformObj.pasien_before	= vDOMpasien && (vDOMpasien.textContent);
		setShowDlgform(true);
	}
	const hdlKDownPasientarget = (_EV) => {
		uDlgformObj.pasien_target_id = 0;
		if(_EV.keyCode !== 13) { setEfekview(!uEfekview); return }
		if((uDlgformObj.pasien_target||"").trim() === "")
			 { setEfekview(!uEfekview); return }

		_EV.preventDefault();
		_EV.stopPropagation();

		setLookuptrigger(true)
		hdlKlikPasientargetlookup();
	}
	const hdlKlikPasientargetlookup = () => {
		uLookupApiObj.headers 		= "Pilih Target Transfer Pasien";
		uLookupApiObj.inputvalue 	= uDlgformObj.pasien_target || "";
		uLookupApiObj.targetapi 	= pjson.homepage+"api/api_pasien/ld_lookup";
		setLookupApishow(true);//-*/
	}
	const hdlKlikLookupapipilih = (_SELECTEDSTR) => {
		_SELECTEDSTR = _SELECTEDSTR||"{}";
		const vLookupObj	= JSON.parse(_SELECTEDSTR);
		if(vLookupObj==={}) return;

		if(parseInt(vLookupObj.id) <= 0) {
			showToast("Pasien Target INVALID !"); return;
		}

		if(parseInt(vLookupObj.id) === uIDPasien) {
			showToast("Pasien Target Harus Berbeda dengan Pasien Sebelumnya !"); return;
		}

		//alert(JSON.stringify(vLookupObj)); return;

		uDlgformObj.pasien_target_id	= parseInt(vLookupObj.id) || 0;
		uDlgformObj.pasien_target		= vLookupObj.nama_pasien || "Undf";
		setLookupApishow(false);
	}
	const hdlKlikRemindertambah = () => {
		uDlgformObj.dlg_jenis		= "DLGREMINDER";
		uDlgformObj.dlg_header		= "Tambah Reminder Pasien";
		setShowDlgform(true);
	}
	const hdlKlikReminderedit = (_IDTABEL) => {
		_IDTABEL			= parseInt(_IDTABEL)||0;

		const vArridx 		= UFunc.getObjectIndeks(uDatareminderArr,_IDTABEL);
		const vCaptionUpper	= UFunc.TglAngka( (uDatareminderArr[vArridx]||{}).tgl_reminder);
		const vRowidx		= UFunc.getTabelRowIndeks("idtabelreminder","idtr"+_IDTABEL);
		setRowreminderselect(vRowidx);

		uDlgformObj.dlg_jenis		= "DLGREMINDER";
		uDlgformObj.dlg_header		= "Edit Reminder: "+vCaptionUpper;
		uDlgformObj.id_tabel 		= _IDTABEL;
		uDlgformObj.tgl_reminder 	= (uDatareminderArr[vArridx]||{}).tgl_reminder;
		uDlgformObj.note_reminder 	= (uDatareminderArr[vArridx]||{}).note_reminder||"";
		setShowDlgform(true);
	}
	const hdlKlikReminderhapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL<=0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatareminderArr,_IDTABEL);
		if(vArridx < 0) return;
		const vRowidx		= UFunc.getTabelRowIndeks("idtabelreminder","idtr"+_IDTABEL);
		setRowreminderselect(vRowidx);

		const vCaptionUpper = (UFunc.TglAngka(uDatareminderArr[vArridx].tgl_reminder)).toUpperCase();

		const vConfirm	= 
			(uBahasaObj.caption_hapusreminder||"Hapus Reminder")+": <b>"+
			vCaptionUpper+"</b>"+
			"<br/>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<br/><br/><b>"+(uBahasaObj.confirm_yakin||"confirm_yakin")+"</b>"+
		"";

		if(await Konfirm(vConfirm)) apiHapusreminder(_IDTABEL);
	}
	const hdlKlikDlgformsimpan = () => {
		if(uDlgformObj.dlg_jenis === "DLGSWAP") { prosesSimpanswap(); return }
		if(uDlgformObj.dlg_jenis === "DLGREMINDER") { prosesSimpanreminder(); return }
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initSelectrowmain = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabelmain","idtr"+_IDTABEL);
		setRowmainselect(vRowIdx);
	}
	const prosesSimpanswap = () => {
		if(parseInt(uDlgformObj.pasien_target_id) <= 0) return;
		apiProsestranfer(uDlgformObj.pasien_target_id);
	}
	const prosesSimpanreminder = () => {
		let vElfocus 	= document.getElementById("inpremindertgl");
		if((uDlgformObj.tgl_reminder||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Tgl Reminder Tidak Valid !"); return;
		}
		const vTglreminderDT	= UFunc.toDate(uDlgformObj.tgl_reminder);
		const vTglnowDT 		= UFunc.toDate(UFunc.DbDate());
		if(vTglreminderDT < vTglnowDT) {
			vElfocus && vElfocus.focus();
			showToast("Tgl Reminder Sudah Terlewat !"); return;
		}

		vElfocus 	= document.getElementById("inpremindernote");
		if((uDlgformObj.note_reminder||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Note Reminder Tidak Valid !"); return;
		}

		apiProsesreminder();
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentReminder = () => {
		if(uHTMLError500) return "";

		if(uISReminderloading) return (
		<div style={{minHeight:100}} className="d-flex justify-content-center align-items-center">
			<CSpinner color="dark"/>
		</div>
		)//>

		if(uReminderError500) return (
		<div className="p-3 py-lg-5">{UFunc.renderHTML(uReminderError500)}</div>
		)//>

		return (
		<table className="table table-borderless table-striped" id="idtabelreminder">
		<thead>
			<tr className="d-none d-md-table-row align-top text-center classfontsmaller">
			<th width="5%" className="px-1 align-middle"><small>No</small></th>
			<th className="px-1 align-middle">
			<small>
			<CRow className="m-0">
				<CCol md="3" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol md="1" className="">&middot;</CCol>
				<CCol md="8" className="text-start">{uBahasaObj.caption_catatanreminder||"Catatan Reminder"}</CCol>
			</CRow>
			</small>
			</th>
			</tr>
		</thead>
		<tbody>
		{uDatareminderArr.map((vItems,vKeys)=>{
			const {
				tgl_reminder,note_reminder,id
			} = vItems;

			return (
			<tr id={"idtr"+id} key={vKeys} 
				className={"text-end"+(uRowreminderselect===vKeys?" classrowselect":"")}>
			<td className="px-1 pe-0 classfontsmaller"><small>{(vKeys+1)}.</small></td>
			<td className="px-1">
			<small>
			<CRow className="mx-0">
				<CCol xs="12" md="3" className="px-1 text-start d-flex justify-content-between">
					<div>
					<span className="d-none d-md-block">
						<small>{UFunc.TglAngka(tgl_reminder)}</small>
					</span>
					<span className="d-md-none">{UFunc.TglAngka(tgl_reminder)}</span>
					</div>

					<CDropdown className="d-md-none">
						<CDropdownToggle color="transparent"
							className="p-0 m-0" caret={false}>
							<CIcon icon="cilOptions" className="classikoncoreui" height={23}/>
						</CDropdownToggle>

						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikReminderedit(id)}>
							<CIcon className="classikontabel classikonedit me-2" height={20}/>
							{uBahasaObj.caption_editreminder||"Edit Reminder"}
						</CDropdownItem>
						
						<CDropdownDivider/>
						<CDropdownItem onClick={()=>hdlKlikReminderhapus(id)}>
							<CIcon className="classikontabel classikonhapus me-2" height={20}/>
							{uBahasaObj.caption_hapusreminder||"Hapus Reminder"}
						</CDropdownItem>
						</CDropdownMenu>
					</CDropdown>
				</CCol>

				<CCol xs="12" className="classborderbottom d-md-none my-3"/>

				<CCol md="1" className="px-1 text-center d-none d-md-block">
					<CDropdown>
						<CDropdownToggle color="transparent"
							className="p-0 m-0" caret={false}>
							<CIcon icon="cilChevronBottom" className="classikoncoreui" height={23}/>
						</CDropdownToggle>

						<CDropdownMenu>
						<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikReminderedit(id)}>
							<CIcon className="classikontabel classikonedit ms-0" height={20}/>
							{uBahasaObj.caption_editreminder||"Edit Reminder"}
						</CDropdownItem>
						
						<CDropdownDivider/>
						<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikReminderhapus(id)}>
							<CIcon className="classikontabel classikonhapus ms-0" height={20}/>
							{uBahasaObj.caption_hapusreminder||"Hapus Reminder"}
						</CDropdownItem>
						</CDropdownMenu>
					</CDropdown>
				</CCol>

				<CCol xs="12" md="8" className="px-1 text-start">
					<i>{(note_reminder || "-")}</i>
				</CCol>
			</CRow>
			</small>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentRekammedis = () => {
		if(uHTMLError500) return ""

		if(uISMainloading) return (
		<div style={{minHeight:100}} className="d-flex justify-content-center align-items-center">
			<CSpinner color="dark"/>
		</div>
		)//>

		if(uMainError500) return (
		<div className="p-3 py-lg-5">{UFunc.renderHTML(uMainError500)}</div>
		)//>

		const vMin			= (uPageactivemain-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		//console.log("(Pasienrekammedis - contentRekammedis) uPageactivemain = "+uPageactivemain);

		//--PAGINATION_DIATUR_BACKEND--/
		const vDataviewArr	= uDatamainArr.filter(vItems=> parseInt(vItems.pg||1)===parseInt(uPageactivemain||1))
		//--END PAGINATION_DIATUR_BACKEND--*/

		/*//--PAGINATION_DIATUR_FRONTEND--/
		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));
		//--END PAGINATION_DIATUR_FRONTEND--*/

		return (
		<table className="table table-borderless table-striped" id="idtabelmain">
		<thead>
			<tr className="d-none d-md-table-row align-top text-center classfontsmaller">
			<th width="5%" className="px-1 align-middle"><small>No</small></th>
			<th className="px-1 align-middle">
			<small>
			<CRow className="mx-0">
				<CCol md="2" lg="1" className="text-start px-0">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol md="4" lg="3" className="text-start">{uBahasaObj.word_anamnesa||"word_anamnesa"}</CCol>
				<CCol md="2" className="d-md-none d-lg-block">{uBahasaObj.word_layanan||"Layanan"}</CCol>
				<CCol md="5" className="text-start">{uBahasaObj.caption_itemterapi||"caption_itemterapi"}</CCol>
				<CCol md="1" className="">&middot;</CCol>
			</CRow>
			</small>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tanggal,diagnosa,anamnesa,itemsarr,
				layanan_id,layanan_value,isAdafoto,
				init_collapse
			} = vItems;

			vNumber++;

			const vIdx = UFunc.getObjectIndeks(uLayananArr,layanan_id);
			const vLayanancolor		= vIdx>=0 ? uLayananArr[vIdx].color_layanan : "000000";
			const vLayananccaption	= vIdx>=0 
				? uBahasaObj["layanan_"+(uLayananArr[vIdx].value_layanan || "periksa").toLowerCase()]
				: "Undf";

			const vCaptionUpper	= UFunc.HariAngka(tanggal||"")+
				(vLayananccaption!==""?" - "+vLayananccaption.toUpperCase():"")+
			"";
			const vInitCollapse	= (parseInt(init_collapse)||0)<=0 ? false : true; 
			const vBgColor 		= (vLayanancolor||"")!=="" ? "#"+vLayanancolor+"11" : "intial";

			return (
			<tr id={"idtr"+id} key={vKeys} 
				className={"text-end"+(uRowmainselect===vKeys?" classrowselect":"")}>
			<td className="px-1 pe-0 classfontsmaller"><small>{vNumber}.</small></td>
			<td className="px-1">
			<CRow className="mx-0">
				<CCol xs="12" md="2" lg="1" className="px-1 text-start d-flex justify-content-between">
					<div className="text-info">
					<span className="classfontsmaller d-none d-md-block">
						<small>{UFunc.TglAngka(tanggal)}</small>
					</span>
					<span className="d-md-none">{UFunc.TglAngka(tanggal)}</span>
					</div>

					<CDropdown className="d-md-none">
						<CDropdownToggle color="transparent"
							className="p-0 m-0" caret={false}>
							<CIcon icon="cilOptions" className="classikoncoreui" height={23}/>
						</CDropdownToggle>

						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikFoto(id)}>
							<CIcon className="classikontabel classikonfoto me-2" height={20}/>
							{uBahasaObj.caption_previewfoto||"caption_previewfoto"}
						</CDropdownItem>
						
						<CDropdownItem onClick={()=>hdlKlikDetil(id)}>
							<CIcon className="classikontabel classikontreatment me-2" height={20}/>
							{uBahasaObj.caption_detilrekammedis||"caption_detilrekammedis"}
						</CDropdownItem>

						<CDropdownDivider/>

						<CDropdownItem onClick={()=>hdlKlikSwap(id)}>
							<CIcon icon="cilTransfer" className="classikoncoreui me-2" height={20}/>
							{uBahasaObj.caption_swappasien||"Swap Data ke Pasien Lain"}
						</CDropdownItem>
						</CDropdownMenu>
					</CDropdown>
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

				<CCol xs="4" className="px-1 text-start d-md-none classfontsmaller">{uBahasaObj.word_anamnesa}</CCol>
				<CCol xs="8" md="4" lg="3" className="px-1 text-md-start text-warning text-capitalize">
					<small className="d-md-none">{anamnesa||"-"}</small>
					<span className="classfontsmaller d-none d-md-block"><small>{anamnesa||"-"}</small></span>
				</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none classfontsmaller">{uBahasaObj.word_layanan||"Layanan"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-center d-md-none d-lg-block">
					{layanan_value ? (
					<small className="fst-italic text-capitalize" style={{color:"#"+vLayanancolor}}>{vLayananccaption}</small>
					) : (
					<span className="text-muted classfontsmaller"><small>&middot;undf&middot;</small></span>
					)}
					{(isAdafoto||false)===true && (
					<div className="d-none d-md-block">
						<CTooltip content={"--"+uBahasaObj.caption_previewfoto+": "+vCaptionUpper}>
						<CLink 
							onClick={()=>hdlKlikFoto(id)} 
							className="classcursorpointer classikontabel classikonfoto"/>
						</CTooltip>
					</div>
					)}
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom" />

				<CCol md="5" className="px-1 text-start classfontsmaller">
					<div className="text-uppercase d-flex d-md-block justify-content-between">
					<small className="fw-bolder">
						{uBahasaObj.word_item||"word_item"}:{" "}
					</small>
					{parseInt(uItemloadingID||"0")!==parseInt(id||"0") ? (
						<CLink className={"d-none classcursorpointer"+((parseInt(uItemloadingID)||0)===(parseInt(id)||0)?" d-none":"")}
							onClick={()=>{hdlKlikItemcollapse(id)}}>
						<CIcon icon={(vInitCollapse===false ? "cilArrowBottom":"cilArrowTop")} 
							className="classikoncoreui"
							height={23}/>
						</CLink>
					) : (
						<CSpinner size="sm" className="mx-1" color="primary"/>
					)}
					</div>

					{(itemsarr||[]).length > 0 && (
					<CCollapse visible={vInitCollapse}>
					<ol className="m-0 p-0 ps-4">
					{itemsarr.map((vItemrm,vKeyrm)=>{
						const {
							id,item_caption,item_harga,item_qty
						} = vItemrm;

						return (
						<li key={vKeyrm} className="mx-0 py-1 classborderbottom">
						<small>
							<span className="fst-italic">{item_caption}</span>
							{" "}
							(
							<b className="text-success">{UFunc.formatAngka(item_qty||"1")}</b>
							<span className="mx-1">x</span>
							@<b className="text-success">Rp {UFunc.formatAngka(item_harga)}</b>
							)
						</small>
						</li>
						)
					})}
					</ol>
					</CCollapse>
					)}
				</CCol>

				<CCol md="1" className="px-0 text-md-center d-none d-md-block">
					<div className="d-md-none d-lg-block">
					<CTooltip content={"--"+(uBahasaObj.caption_swappasien||"Swap Data ke Pasien Lain")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikSwap(id)} 
						className="classcursorpointer">
						<CIcon icon="cilTransfer" className="classikoncoreui ms-0" height={25}/>
					</CLink>
					</CTooltip>

					<CTooltip content={"--"+(uBahasaObj.caption_detilrekammedis||"caption_detilrekammedis")+": "+vCaptionUpper}>
					<CLink
						onClick={()=>hdlKlikDetil(id)} 
						className="classcursorpointer classikoncoreui classikontabel classikontreatment me-0"/>
					</CTooltip>
					</div>

					<CDropdown className="d-lg-none">
						<CDropdownToggle color="transparent" className="p-0 m-0" caret={false}>
							<CIcon icon="cilChevronBottom" className="classikoncoreui" height={23}/>
						</CDropdownToggle>

						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikFoto(id)}>
							<CIcon className="classikontabel classikonfoto me-2" height={20}/>
							{uBahasaObj.caption_previewfoto||"caption_previewfoto"}
						</CDropdownItem>

						<CDropdownItem onClick={()=>hdlKlikDetil(id)}>
							<CIcon className="classikontabel classikontreatment me-2" height={20}/>
							{uBahasaObj.caption_detilrekammedis||"caption_detilrekammedis"}
						</CDropdownItem>

						<CDropdownDivider/>

						<CDropdownItem onClick={()=>hdlKlikSwap(id)}>
							<CIcon icon="cilTransfer" className="classikoncoreui me-2" height={20}/>
							{uBahasaObj.caption_swappasien||"Swap Data ke Pasien Lain"}
						</CDropdownItem>
						</CDropdownMenu>
					</CDropdown>
				</CCol>
			</CRow>
			</td>
			</tr>
			);
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsShowDlgview) return (<></>)//>
		if(uIDmainpilih <= 0) return (<></>)//>

		const vObjidx	= UFunc.getObjectIndeks(uDatamainArr,uIDmainpilih)
		if(vObjidx < 0) return (<></>)
		const vFotoArr 	= uDatamainArr[vObjidx].foto_arr || [];
		let vFotodlgArr	= []
		vFotoArr.map(vItems=>{
			const vTmpObj = {
				original:vItems.url_image,
				thumbnail:vItems.url_image,
				thumbnailHeight:85,
				thumbnailWidth:85,
			}
			vFotodlgArr.push(vTmpObj);
		})//>

		return (
		<ImageGallery 
			items={vFotodlgArr}/>
		)//>
	}
	const contentDlgform = () => {
		if(!uIsShowDlgform) return (<></>)//>

		if(uDlgformObj.dlg_jenis === "DLGSWAP") return contentFormswap();
		if(uDlgformObj.dlg_jenis === "DLGREMINDER") return contentFormreminder();
		
		return (<></>)//>
	}
	const contentFormswap = () => {
		if(uDlgformObj.dlg_jenis !== "DLGSWAP") return (<></>)//>

		return (
		<>
		<CRow className="my-2">
		<CCol className=""><small>Pasien Sebelum</small></CCol>
		<CCol xs="8" className=""><big>{uDlgformObj.pasien_before}</big></CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2 fw-bolder d-none">
		<CCol className="">Target SWAP Pasien</CCol>
		<CCol xs="6" className="pe-0">
			{(parseInt(uDlgformObj.pasien_target_id)||0) <= 0 ? (
				<small className="fst-italic text-muted">&middot; Pilih Target Pasien &middot;</small>
			) : (
				<span className="text-primary">{uDlgformObj.pasien_target||""}</span>
			)}
		</CCol>
		<CCol xs="1" className="ps-0">
			<CLink onClick={()=>hdlKlikPasientargetlookup()} className="classcursorpointer">
			<CIcon icon="cilSearch" className="classikoncoreui" height={30}/>
			</CLink>
		</CCol>
		</CRow>

		<CRow className="my-2 fw-bolder">
		<CCol className=""><small>Target SWAP Pasien</small></CCol>
		<CCol xs="8">
			<CInputGroup size="sm">
			<CFormInput size="sm"
				placeholder="--Pilih Pasien Terget.."
				value={uDlgformObj.pasien_target}
				onChange={(e)=>{uDlgformObj.pasien_target=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>hdlKDownPasientarget(e)}
				id="inppasientargetlookup"/>
			<CButton color="dark"
				onClick={()=>hdlKlikPasientargetlookup()}
				id="btnpasientargetlookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
		</CCol>
		</CRow>
		</>
		)//>
	}
	const contentFormreminder = () => {
		if(uDlgformObj.dlg_jenis !== "DLGREMINDER") return (<></>)//>

		return (
		<CForm>
		<CRow className="my-2">
			<CCol xs="4">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
			<CCol xs="8" lg="6">
			<CFormInput size="sm" type="date"
				value={uDlgformObj.tgl_reminder}
				onChange={(e)=>{ uDlgformObj.tgl_reminder = e.target.value;setEfekview(!uEfekview)} }
				id="inpremindertgl"/>
			</CCol>
		</CRow>
		
		<div className="classborderbottom my-3"/>

		<CRow className="my-2">
			<CCol xs="12">{uBahasaObj.caption_catreminder||"caption_catreminder"}</CCol>
			<CCol xs="12" className="my-1">
			<CFormInput size="sm" maxLength="255"
				value={uDlgformObj.note_reminder||""}
				className="text-capitalize"
				onChange={(e)=>{uDlgformObj.note_reminder = e.target.value; setEfekview(!uEfekview);}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpremindernote"/>
			</CCol>
		</CRow>
		</CForm>
		)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = () => {
		setHTMLError500();

		setSessionaktif(false);
		setLoading(true);

		const vFuncProcess 	= [];
		vFuncProcess.push((() => { 
			const vPageinit		= parseInt(uTabelSelectObj.initPage)||1;
			const vLayananID	= uKeywordObj.kwd_rmlayananid || "0";
			const vURLs			= pjson.homepage+"api/api_rekammedis/ld_data";
			const vDATAS	= JSON.stringify({
				send_pasienid : uIDPasien,
				send_layananid : vLayananID,
				send_pg	: vPageinit,//--PAGINATION_DIATUR_BACKEND-
				send_maxdata	: uMaxData,//--PAGINATION_DIATUR_BACKEND-
				send_firstload	: "YA",//--PAGINATION_DIATUR_BACKEND-
				send_tokenauth : uTokenObj.userinit
			});
			const vHeaderObj	= {
				headers: { Accept: "application/json", "Content-Type": "application/json" },
				method: "POST",
				mode: "cors",
				cache: "default",
				body: vDATAS,
			}

			setTimeElapsed(0);
			const vTimeBegin = new Date();
			setMainError500();

			setMainloading(true);
			return new Promise((onResolve,onReject)=>{
				//onResolve(apiLoadrekammedis(vPageinit));
				onResolve(
					fetch(vURLs,vHeaderObj
					).then((response)=> { if (response.status === 200) return response.json(); }
					).then((output_string) => {
						setMainloading(false);
						if(output_string.tampil) {
							console.log("(Pasienrekammedis - apiLoaddata) MAIN LEWAT "+UFunc.JamMenitDetik());
							const vLoadArr	= JSON.parse(output_string.datamainobject||"[]")
							//--PAGINATION_DIATUR_BACKEND--/
							vLoadArr.map(vItems=>vItems.pg = vPageinit);
							setDatamainArr(vLoadArr);
							setJmldatamain(parseInt(output_string.totaldata||uJmldatamain))

							console.log("(Pasienrekammedis - apiLoaddata) MAIN uIsInitbefore "+uIsInitbefore);
							if(uIsInitbefore) {
								setPageactivemain(vPageinit); 
								let vTimeout = setTimeout(()=>{
									clearTimeout(vTimeout);
									setInitbeforeprocess(true)
								},250);
							}
							//--END PAGINATION_DIATUR_BACKEND--/

							const vTimeEnd = new Date();
							const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
							setTimeElapsed(vTimeDiff);
						} else if(output_string.info) {
							setMainError500(output_string.info);
						} else if(output_string.errors) {
							console.log("(Pasienrekammedis-apiLoadRekammedis) output_string.errors : "+output_string.errors);
							const vMsg = (pjson.mydefault.environment==="development")
								? output_string.errors : pjson.mydefault.msgFetchError;
							setMainError500("<font color='red'>"+vMsg+"</font>");
						} else if(output_string.expired) {
							prosesExpired();
						}
					})
				);
				//onReject(()=>{ setMainloading(false) });//-*/
			}); 
		})());
		vFuncProcess.push((() => { 
			const vURLs			= pjson.homepage+"api/api_rekammedis/ld_reminder";
			const vDATAS	= JSON.stringify({
				send_pasienid : uIDPasien,
				send_tokenauth : uTokenObj.userinit
			});
			const vHeaderObj	= {
				headers: { Accept: "application/json", "Content-Type": "application/json" },
				method: "POST",
				mode: "cors",
				cache: "default",
				body: vDATAS,
			}

			setJmldatareminder(0);
			setDatareminderArr([]);
			setRowreminderselect(-1);
			setPageactivereminder(1);
			setReminderError500();

			setReminderloading(true);
			return new Promise((onResolve,onReject)=>{
				//onResolve(apiLoadreminder());
				onResolve(
					fetch(vURLs,vHeaderObj
					).then((response)=> { if (response.status === 200) return response.json(); }
					).then((output_string) => {
						setReminderloading(false);
						if(output_string.tampil) {
							console.log("(Pasienrekammedis - apiLoaddata) REMINDER LEWAT "+UFunc.JamMenitDetik());
							setDatareminderArr(JSON.parse(output_string.dataobject || "[]"));
						} else if(output_string.info) {
							setReminderError500(output_string.info);
						} else if(output_string.errors) {
							console.log("(Pasienrekammedis-apiLoadRekammedis) output_string.errors : "+output_string.errors);
							const vMsg = (pjson.mydefault.environment==="development")
								? output_string.errors : pjson.mydefault.msgFetchError;
							setReminderError500("<font color='red'>"+vMsg+"</font>");
						} else if(output_string.expired) {
							prosesExpired();
						}
					})
				);
			});
		})());

		console.log("(Pasienrekammedis - apiLoaddata) => Start LEWAT 1 "+UFunc.JamMenitDetik());
		const vTimeBegin = new Date();
		Promise.all(vFuncProcess).then(() => {
			const vTimeEnd = new Date();
			const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(vTimeDiff <= 1000) {
				let vTimeout = setTimeout(()=>{
					clearTimeout(vTimeout);
					setSessionaktif(true);
					console.log("(Pasienrekammedis - apiLoaddata) setSessionaktif : "+UFunc.JamMenitDetik());
				},1000);
			} else setSessionaktif(true);

			setFirstload("TIDAK");
			setLoading(false);

			console.log("(Pasienrekammedis - apiLoaddata) => then ["+vTimeDiff+"ms] LEWAT 2 "+UFunc.JamMenitDetik())
		}).catch((vErrorlogs)=>{
			setLoading(false);
			console.log("(Pasienrekammedis - apiLoaddata) => catch LEWAT 3a => "+vErrorlogs)
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadrekammedis = (_PAGE) => {
		setMainError500();

		//console.log("(Pasienrekammedis - apiLoadrekammedis) new Date "+(new Date()));
		const vLayananID	= uKeywordObj.kwd_rmlayananid || "0";
		//--PAGINATION_DIATUR_BACKEND--/
		//console.log("(Pasienrekammedis - apiLoadrekammedis) uPageactivemain "+uPageactivemain);
		const vHandelview	= (!_PAGE || uFirstload==="YA") ? "YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		//--END PAGINATION_DIATUR_BACKEND--*/

		/*//--PAGINATION_DIATUR_FRONTEND--/
		setJmldatamain(0);
		setDatamainArr([]);
		setRowmainselect(-1);
		setPageactivemain(1);
		//--END PAGINATION_DIATUR_FRONTEND--*/
		setMainloading(true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vPage		= parseInt(_PAGE)||1;
		const vDATAS	= JSON.stringify({
			send_pasienid : uIDPasien,
			send_layananid : vLayananID,
			send_pg	: vPage,//--PAGINATION_DIATUR_BACKEND-
			send_maxdata	: uMaxData,//--PAGINATION_DIATUR_BACKEND-
			send_firstload	: vHandelview,//--PAGINATION_DIATUR_BACKEND-
			//send_firstload	: uFirstload,//--PAGINATION_DIATUR_FRONTEND
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setMainloading(false);
			setLoading(false);
			if(output_string.tampil) {
				console.log("(Pasienrekammedis - apiLoadrekammedis) LEWAT "+UFunc.JamMenitDetik());
				const vLoadArr	= JSON.parse(output_string.datamainobject||"[]")
				//--PAGINATION_DIATUR_BACKEND--/
				vLoadArr.map(vItems=>vItems.pg = vPage);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				setJmldatamain(parseInt(output_string.totaldata||uJmldatamain))

				if(uIsInitbefore) {
					setPageactivemain(vPage); 
					let vTimeout = setTimeout(()=>{
						clearTimeout(vTimeout);
						setInitbeforeprocess(true)
					},250);
				}
				//--END PAGINATION_DIATUR_BACKEND--*/

				/*//--PAGINATION_DIATUR_FRONTEND--/
				setDatamainArr(vLoadArr);
				//--END PAGINATION_DIATUR_FRONTEND--*/

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setMainError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasienrekammedis-apiLoadRekammedis) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setMainError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setMainloading(false);
			setLoading(false);
			console.log("(Pasienrekammedis-apiLoadRekammedis) catch-error: "+error);
			setMainError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadreminder = () => {
		setReminderError500();

		//--PAGINATION_DIATUR_FRONTEND--/
		setJmldatareminder(0);
		setDatareminderArr([]);
		setRowreminderselect(-1);
		setPageactivereminder(1);
		//--END PAGINATION_DIATUR_FRONTEND--*/
		setReminderloading(true);
		/*//--TESTING_FRONTEND--/
		//console.log("("+uComponentName+"-"+uFuncname+") vInitCollapse 2 : "+vInitCollapse);
		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
		},1500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uIDPasien,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_reminder";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setReminderloading(false);
			if(output_string.tampil) {
				console.log("(Pasienrekammedis - apiLoadreminder) LEWAT "+UFunc.JamMenitDetik());
				setDatareminderArr(JSON.parse(output_string.dataobject || "[]"));
			} else if(output_string.info) {
				setReminderError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasienrekammedis-apiLoadreminder) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setReminderError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setReminderloading(false);
			console.log("(Pasienrekammedis-apiLoadreminder) catch-error: "+error);
			setReminderError500("<font color='red'>"+pjson.mydefault.msg500str.join(" ")+"</font>");
		});
	}
	const apiLoadItem = (_IDTABEL) => {//--SEPERTINYA_TIDAK_DIPAKAI_LAGI--/
		uFuncname = apiLoadItem.name;

		_IDTABEL = parseInt(_IDTABEL) || 0;
		const vObjidx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vInitCollapse = (parseInt(uDatamainArr[vObjidx].init_collapse) || 0) > 0 ? 0 : 1; 
		uDatamainArr[vObjidx].init_collapse	= vInitCollapse;
		const vItemsarr		= uDatamainArr[vObjidx].itemsarr || [];

		if(vInitCollapse <= 0 || vItemsarr.length > 0) { setBodyInit(!uBodyInit); return; }

		setItemloadingID(_IDTABEL);
		/*//--TESTING_FRONTEND--/
		//console.log("("+uComponentName+"-"+uFuncname+") vInitCollapse 2 : "+vInitCollapse);
		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
		},1500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_item";

		const vInitCollapseErr = vInitCollapse > 0 ? 0 : 1; 
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setItemloadingID(0);
			if(output_string.tampil) {
				let vTmpObj = [];
				//console.log("(Pasienrekammedis-"+uFuncname+") dataobject : "+JSON.stringify(output_string.dataobject));
				if(output_string.dataobject) {
					uDatamainArr[vObjidx].itemsarr = JSON.parse(output_string.dataobject||"[]");
					setBodyInit(!uBodyInit);
				}
			} else if(output_string.info) {
				uDatamainArr[vObjidx].init_collapse	= vInitCollapseErr;
				showToast(output_string.info);
			} else if(output_string.errors) {
				uDatamainArr[vObjidx].init_collapse	= vInitCollapseErr;
				console.log("(Pasienrekammedis-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjidx].init_collapse	= vInitCollapseErr;
			setItemloadingID(0);
			console.log("(Pasienrekammedis-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadfoto = (_IDTABEL) => {
		uFuncname = apiLoadfoto.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;

		const vObjidx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		//console.log("("+uComponentName+"-"+uFuncname+") vInitCollapse 2 : "+vInitCollapse);
		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)
		},1500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});

		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_foto";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				uDatamainArr[vObjidx].foto_arr = JSON.parse(output_string.fotolist||"[]")
				//console.log("(Pasienrekammedis - apiLoadfoto) fotolist => "+output_string.fotolist);

				if((output_string.fotolist||"[]")!=="[]") setShowDlgview(true);
				else showToast(uBahasaObj.caption_dataempty||"caption_dataempty");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasienrekammedis-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pasienrekammedis-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsestranfer = (_IDPASIENTARGET) => {
		_IDPASIENTARGET = parseInt(_IDPASIENTARGET)||0; if(_IDPASIENTARGET <= 0) return;
		if(uIDmainpilih <= 0) return;

		const vElbutton		= document.getElementById("btnDialogSimpan");
		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,uIDmainpilih);
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		//console.log("("+uComponentName+"-"+uFuncname+") vInitCollapse 2 : "+vInitCollapse);
		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);

			setLoading(false);
			uDatamainArr.splice(vArridx,1);
			setShowDlgform(false);
			showToast("WOKEEE !","SUKSES")
		},1500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uIDmainpilih,
			send_targetid : _IDPASIENTARGET,
			send_pasienid : uIDPasien,
			send_tokenauth : uTokenObj.userinit
		});

		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_transfer";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setShowDlgform(false);
	
				showToast(uBahasaObj.pesan_berhasil||"Berhasil","SUKSES");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasienrekammedis - apiProsestranfer) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Pasienrekammedis - apiProsestranfer) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesreminder = () => {
		//document.getElementById("btnDialogSimpan").disabled = true
		const vEls 		= document.getElementById("btnDialogSimpan")
		vEls && (vEls.disabled = true)
		setLoading(true)

		/*//---TEST_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2000); return
		//---END TEST_FRONTEND--*/

		const vParsObj	= {
			jenis: "FORMMAIN",
			tgl_reminder: uDlgformObj.tgl_reminder,
			note_reminder: uDlgformObj.note_reminder,
			pasien_id: uIDPasien,
		}

		const vDATAS	= JSON.stringify({
			send_formlist : JSON.stringify(vParsObj),
			send_tabelid : parseInt(uDlgformObj.id_tabel)||0,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_reminder/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			vEls && (vEls.disabled = false)
			setLoading(false);
			if(output_string.sukses) {
				const vTabelid = parseInt(uDlgformObj.id_tabel)||0;
				if(vTabelid <= 0) {
					const vTmpObj	= {
						id: output_string.tabelid || "0",
						tgl_reminder: uDlgformObj.tgl_reminder,
						note_reminder: uDlgformObj.note_reminder,
					}
					setDatareminderArr([vTmpObj,...uDatareminderArr])
				} else {
					const vArridx = UFunc.getObjectIndeks(uDatareminderArr,vTabelid);

					uDatareminderArr[vArridx].tgl_reminder 	= uDlgformObj.tgl_reminder;
					uDatareminderArr[vArridx].note_reminder	= uDlgformObj.note_reminder;
				}
				setShowDlgform(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasienrekammedis - apiProsesreminder) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEls && (vEls.disabled = false)
			setLoading(false)
			console.log("(Pasienrekammedis - apiProsesreminder) catch-error: "+error)
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR")
		});
	}
	const apiHapusreminder = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vObjidx	= UFunc.getObjectIndeks(uDatareminderArr,_IDTABEL);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") LEWAT ");
		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_reminder/pr_ongoinghapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatareminderArr.splice(vObjidx,1);
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasienrekammedis - apiHapusreminder) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);

			console.log("(Pasienrekammedis - apiHapusreminder) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		const vThisurl	= window.location.pathname + window.location.search;
		uDispatch({type: "set", gUrlbefore: vThisurl});

    	return ()=>{
			hdlToggleDlgview(); setLookupApishow(false); setShowDlgform(false);
    		setDatamainArr([]); setDatareminderArr([]); setFirstload("YA");
    	}
	},[]);
	React.useEffect(()=>{
		setPasienRefresh(!uPasienRefresh);
		if(uIsInitbefore) return;

		//console.log("(Pasienrekammedis-[uIDPasien]) uIDPasien = "+uIDPasien);
		//--PAGINATION_DIATUR_BACKEND--/
		setJmldatamain(0);
		setDatamainArr([]);
		setIDmainpilih(0);
		setRowmainselect(-1);
		setPageactivemain(1);
		//--END PAGINATION_DIATUR_BACKEND--*/

		//setReminderloading(true); apiLoadrekammedis();
		apiLoaddata();
	},[uIDPasien]);
	//--PAGINATION_DIATUR_BACKEND--/
	React.useEffect(()=>{
		if(!uIsInitbefore) return;

		apiLoaddata();
	},[uIsInitbefore]);
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		console.log("(Pasienrekammedis-[uInitbeforeprocess]) uInitbeforeprocess = "+(uInitbeforeprocess));
		const vInitID	= uTabelSelectObj.initID;
		initSelectrowmain(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		console.log("(Pasienrekammedis - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		uDispatch({type: "set", gInitRMSelected: undefined}); 
		setInitbeforeprocess(false)
	},[uInitbeforeprocess]);
	React.useEffect(()=>{ 
		console.log("(Pasienrekammedis-[uPageactivemain]) uFirstload = "+(uFirstload));
		if(uFirstload==="YA") return;

		setIDmainpilih(0);
		setRowmainselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageactivemain||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoadrekammedis(uPageactivemain);
	},[uPageactivemain]); 
	//--END PAGINATION_DIATUR_BACKEND--*/
	//--PAGINATION_DIATUR_FRONTEND--/
	React.useEffect(()=>{ setRowreminderselect(-1); },[uPageactivereminder]);
	React.useEffect(()=>{
		setJmldatareminder(uDatareminderArr.length);
	},[uDatareminderArr,uDatareminderArr.length])
	//--END PAGINATION_DIATUR_FRONTEND--*/
	React.useEffect(()=>{
		let vJmlhalmain	= Math.ceil(uJmldatamain / uMaxData);
		setJmlhalmain(vJmlhalmain);//-*/

		setPageactivemain(uPageactivemain<=0?1:uPageactivemain);
	},[uJmldatamain]);
	React.useEffect(()=>{
		let vJmlhalmain	= Math.ceil(uJmldatareminder / uMaxData);
		setJmlhalreminder(vJmlhalmain);//-*/

		setPageactivereminder(uPageactivereminder<=0?1:uPageactivereminder);
	},[uJmldatamain]);
	/*React.useEffect(()=>{ 
		console.log("(Pasienrekammedis-[uLayananID]) uFirstload = "+(uFirstload));
		if(uFirstload === "YA") return; 

		hdlChangeLayanan(uLayananID);
	},[uLayananID]);//-*/
	React.useEffect(()=>{ 
		if(!uIsShowDlgview) { setIDmainpilih(0); return }
	},[uIsShowDlgview]); 
	React.useEffect(()=>{ 
		if(!uISLookupApishow) { 
			setLookuptrigger(false);
			setLookupApiObj({}); 
			return 
		}
	},[uISLookupApishow]); 
	React.useEffect(()=>{ 
		if(!uIsShowDlgform) { setDlgformObj({}); return }

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(!isMobile) {
				let vElfocus;

				if(uDlgformObj.dlg_jenis === "DLGSWAP")
					vElfocus = document.getElementById("inppasientargetlookup");
				else if(uDlgformObj.dlg_jenis === "DLGREMINDER")
					vElfocus = document.getElementById("inpremindertgl");

				vElfocus && vElfocus.focus();
			}
		},350)
	},[uIsShowDlgform]); 
	//---END EFFECT--/

	//console.log("(Pasienrekammedis) Checked..");//---KALO_GA_ADA_YG_PAKE_cComponentName
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="px-3 classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className=""
				onClick={() =>{
					if(uPasienstateURL!=="") uNavigate(uPasienstateURL,{replace:true}); 
					else uNavigate(-1);
				}}>
			<CIcon icon="cilArrowCircleLeft" className="" height={25}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.caption_daftartreatment||"caption_daftartreatment").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
		<CContainer xxl className={"px-0"}>
			<MyPage500 content={uHTMLError500} isWithrefresh={true} hdlReset={hdlKlikReset} {...props}/>
		</CContainer>
		</div>
		</div>
	)//>

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadermain"
			style={{height:64}}>
			<CHeaderToggler className="px-0 me-2"
				onClick={() =>{
					uKeywordObj.kwd_rmlayananid = undefined; 
					localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
					uDispatch({type: "set", gInitRMSelected: undefined}); 

					if(uPasienstateURL!=="") uNavigate(uPasienstateURL,{replace:true}); 
					else uNavigate(-1);
				}}>
				<CIcon icon="cilArrowCircleLeft" height={30} className="align-middle" />
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.caption_rekammedispasien||"caption_rekammedispasien")}</small>
				<span className="mx-1 d-none">{uComponentname}</span>
			</CHeaderBrand>

			<CHeaderNav>
				<CLink onClick={()=>hdlKlikReset()} className="d- classcursorpointer classikon classikonreset"/>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2">
		<CContainer fluid className="mb-3">
			<CRow>
			<CCol md="4" lg="3" className="">
			<MyCardPasien pasienID={uIDPasien}
				dataRefresh={uPasienRefresh}
				initLoader={uComponentname.toUpperCase()}
				{...props}/>
			</CCol>
			
			<CCol className="" style={{display:"flex",flexDirection:"column"}}>
			{!uHTMLError500 && (
			<CCard className="classcardbg mt-3 mt-md-0">
			<CCardHeader className="d-flex justify-content-between align-items-center">
				<span>
				<b>Reminder Pasien</b>
				<span className="mx-1">&middot;</span>
				<small className="">(<b id="idjmlreminder">{uJmldatareminder}</b>)</small>
				</span>

				<CTooltip content="--Tambah Reminder Pasien" className="d-none d-lg-block">
				<CLink className="classikon classikontambah classcursorpointer" onClick={()=>hdlKlikRemindertambah()}/>
				</CTooltip>
			</CCardHeader>
			<CCardBody className="px-0">{contentReminder()}</CCardBody>
			</CCard>
			)}

			<CCard className="classcardbg mt-3" style={{flex: "1 0 0"}}>
			<CCardHeader className="d-flex justify-content-between align-items-center">
				<b>{uBahasaObj.caption_datarm||"caption_datarm"}</b>

				<div className="d-flex align-items-center">
				{!uHTMLError500 && (
				<>
				<CFormSelect size="sm" className="d-none d-lg-block"
					style={{minWidth:125,width:"auto"}}
					value={uLayananID}
					onChange={(e)=>hdlChangeLayanan(e.target.value)}
					id="inplayananid">
					<option value="0">--SEMUA LAYANAN--</option>
					{uLayananArr.map((vItems,vKeys)=>{
						return (
						<option value={vItems.id} style={{color:"#"+vItems.color_layanan}} key={vKeys}>{(vItems.caption_layanan||"").toUpperCase()}</option>
						)
					})}
				</CFormSelect>

				<CDropdown className="d-lg-none">
					<CDropdownToggle color="transparent" className="p-0 m-0" caret={false}>
						<CIcon icon="cilEqualizer" className="classikoncoreui" height={23}/>
					</CDropdownToggle>

					<CDropdownMenu>
						<CDropdownItem className={parseInt(uLayananID)<=0 ? "fw-bolder text-uppercase" : ""} onClick={()=>setLayananID(0)}>
							Semua Layanan
						</CDropdownItem>

						{uLayananArr.map((vItems,vKeys)=>{
							return (
							<CDropdownItem key={vKeys} className={parseInt(uLayananID)===parseInt(vItems.id)?"fw-bolder text-uppercase":""} onClick={()=>setLayananID(vItems.id)}>
								<span style={{color:"#"+vItems.color_layanan}}>{vItems.caption_layanan}</span>
							</CDropdownItem>
							)
						})}
					</CDropdownMenu>
				</CDropdown>

				<span className="d-none d-md-block ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikReset()} className=" d-none classcursorpointer classikon classikonreset"/>

				<span className="d-none d-md- me-1">&middot;</span>
				<strong>({uJmldatamain})</strong>
				<span className="mx-1">&middot;</span>
				<span className="classfontsmaller text-muted"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></span>
				</>
				)}
				</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentRekammedis()}</CCardBody>

			{(!isMobile && uJmldatamain > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageactivemain}
					pages={uJmlhalmain}
					onActivePageChange={(i) => setPageactivemain(i)}/>
			</CCardFooter>
			)}
			</CCard>
			</CCol>
			
			</CRow>
		</CContainer>
		</div>
	
		</div>

		<MyDialogview
			renderContent={contentDlgview()}
			dialogHeader={uDlgHeaderview}
			toggle={hdlToggleDlgview}
			options={{centered:false,bgColor:"black"}}
			show={uIsShowDlgview}
			{...props}/>

		<MyDialogform
			renderContent={contentDlgform()}
			dialogHeader={uDlgformObj.dlg_header}
			toggle={()=>setShowDlgform(false)}
			options={{centered:false}}
			show={uIsShowDlgform}
			onSimpan={hdlKlikDlgformsimpan}
			{...props}/>

		<MyDialoglookupapi 
			toggle={()=>setLookupApishow(false)}
			dialogHeader={uLookupApiObj.headers}
			inputValue={uLookupApiObj.inputvalue}
			targetApi={uLookupApiObj.targetapi}
			onKlikPilihItem={hdlKlikLookupapipilih}
			kdownTrigger={uISLookuptrigger}
			show={uISLookupApishow}
			{...props}/>
		</>
	)//>
}
	
export default Pasienrekammedis;
